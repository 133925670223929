<template>
  <div>
    <vm-table
      ref="vmTable"
      :table-data="tableData"
      :total="total"
      :loading="loading"
      :current-page="currentPage"
      :show-add="false"
      @page-change="changePage"
      @add-click="handleAdd"
      @search-click="getList(1)"
    >
      <el-button slot="otherButton" type="primary" @click="exportData">导出</el-button>
      <el-form slot="adSearch" class="form" label-width="110px">
        <div class="vm-separate">
          <el-form-item :label="$t('maintWorkOrder.no')">
            <el-input v-model="adSearch.no" :placeholder="$t('common.pleaseEnter')" clearable></el-input>
          </el-form-item>
          <el-form-item :label="$t('maintWorkOrder.status')">
            <el-select
              v-model="adSearch.status"
              :placeholder="$t('common.pleaseSelect')"
              style="width: 100%"
              clearable>
              <el-option
                v-for="item in stateOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$t('elevator.elevator')">
            <vm-select-input
              v-model="adSearch.elevatorName"
              clearable
              @clear="adSearch.elevatorId=''"
              @select="$refs.selectElevator.open()"></vm-select-input>
            <elevator-select
              ref="selectElevator"
              @select="onSelectElevator"></elevator-select>
          </el-form-item>
          <el-form-item :label="$t('maintWorkOrder.date')">
            <el-date-picker
              v-model="adSearch.dateRange"
              value-format="yyyy-MM-dd"
              type="daterange"
              style="width: 100%"
              :unlink-panels="true"
              :range-separator="$t('common.to')"
              :start-placeholder="$t('common.startDate')"
              :end-placeholder="$t('common.endDate')">
            </el-date-picker>
          </el-form-item>
        </div>
      </el-form>
      <table-column prop="no" :label="$t('maintWorkOrder.no')" width="110px">
        <template #default="scope">
          <router-link :to="'/maintWorkOrder/'+ scope.row.id" target="_blank">{{scope.row.no}}</router-link>
        </template>
      </table-column>
      <table-column prop="elevatorNo" :label="$t('elevator.no')" width="140px"></table-column>
      <table-column prop="elevatorName" :label="$t('elevator.name')"></table-column>
      <table-column prop="maintCom" :label="$t('maintWorkOrder.company')"></table-column>
      <table-column prop="maintainer" :label="$t('elevator.maintWorker') + '1'"></table-column>
      <table-column prop="maintainer1" :label="$t('elevator.maintWorker') + '2'"></table-column>
      <table-column prop="maintDate" :label="$t('maintWorkOrder.date')" width="155px"></table-column>
      <table-column prop="finishTime" label="实际维保时间" width="155px"></table-column>
      <table-column prop="status" :label="$t('maintWorkOrder.status')">
        <template #default="scope">
          <template v-if="scope.row.status === 1">
            <el-tag>{{$t("maintWorkOrder.works")}}</el-tag>
            <el-tag v-if="compareTodayDate(scope.row.maintDate)" type="danger">
              {{$t("maintWorkOrder.ultraTime")}}
            </el-tag>
          </template>
          <template v-else-if="scope.row.status === 2">
            <el-tag>{{$t("maintWorkOrder.execution")}}</el-tag>
            <el-tag v-if="compareTodayDate(scope.row.maintDate)" type="danger">
              {{$t("maintWorkOrder.ultraTime")}}
            </el-tag>
          </template>
          <el-tag v-else-if="scope.row.status === 3" type="warning">
            {{$t("maintWorkOrder.stocks")}}
          </el-tag>
          <el-tag v-else-if="scope.row.status === 4" type="success">
            {{$t("maintWorkOrder.confirmed")}}
          </el-tag>
        </template>
      </table-column>
      <table-column
        v-if="confirmAuth||editAuth||deleteAuth"
        :label="$t('common.operation')"
        width="210px"
        align="right"
        :tooltip="false">
        <template #default="scope">
          <el-button
            v-if="confirmAuth"
            :disabled="scope.row.status !== 3"

            type="success"
            @click="handleSure(scope.row)">
            {{$t("maintWorkOrder.affirm")}}
          </el-button>
          <el-button v-if="editAuth" :disabled="scope.row.status !== 1" @click="handleEdit(scope.row)">
            {{$t("common.edit")}}
          </el-button>
          <el-button v-if="deleteAuth" type="danger" @click="handleDelete(scope.row)">
            {{$t("common.delete")}}
          </el-button>
        </template>
      </table-column>
    </vm-table>
  </div>
</template>
<script>
  import VmTable from "../../../components/VmTableNew.vue";
  import VmSelectInput from "../../../components/VmSelectInput";
  import ElevatorSelect from "../../elevator/ElevatorSelect.vue";

  export default {
    components: {VmTable, VmSelectInput, ElevatorSelect},
    data() {
      return {
        addAuth: this.$auth(314),
        editAuth: this.$auth(311),
        deleteAuth: this.$auth(313),
        confirmAuth: this.$auth(318),
        loading: false,
        currentPage: 1,
        total: 0,
        tableData: [],
        adSearch: {
          no: "",
          status: "",
          elevatorId: "",
          elevatorName: "",
          dateRange: [],
        },
        stateOptions: [
          {value: "1", label: this.$t("maintWorkOrder.works")},
          {value: "2", label: this.$t("maintWorkOrder.execution")},
          {value: "3", label: this.$t("maintWorkOrder.stocks")},
          {value: "4", label: this.$t("maintWorkOrder.confirmed")},
          {value: "5", label: this.$t("maintWorkOrder.hasExtended")}],
        today: "",
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageIndex) {
        this.currentPage = pageIndex;
        this.loading = true;
        let params = {
          pageIndex,
          sort: 0,
          no: this.adSearch.no,
          status: this.adSearch.status,
          elevatorId: this.adSearch.elevatorId,
          startTime: this.adSearch.dateRange ? this.adSearch.dateRange[0] : "",
          endTime: this.adSearch.dateRange ? this.adSearch.dateRange[1] : "",
          integrationKey: "chongqing",
        };
        this.$api.getList("maintWorkOrder", params).then(res => {
          this.loading = false;
          let data = res.data;
          this.tableData = data.list;
          this.total = data.total;
        }).catch(() => {
          this.loading = false;
          this.$message.error(this.$t("common.tip.getDataError"));
        });
      },
      handleAdd() {
        this.$refs.addPage.open();
      },
      handleEdit(row) {
        this.$refs.editPage.open(row);
      },
      handleSure(row) {
        this.$confirm(this.$t("maintWorkOrder.tip.confirm") + row.no + "?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.submitLoading = true;
          this.$axios.put("maintWorkOrder/confirm?id=" + row.id).then(res => {
            this.submitLoading = false;
            this.dialogVisible = false;
            this.getList(this.currentPage);
            this.$message.success(this.$t("common.tip.saveSuccess"));
          }).catch(error => {
            this.submitLoading = false;
            this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
          });
        });
      },
      handleDelete(row) {
        this.$confirm(this.$t("common.delete") + " " + this.$t("maintWorkOrder.no") + " " + row.no + "，" + this.$t("common.isDelete") + "?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.$api.deleteById("maintWorkOrder", row.id).then(res => {
            this.getList(this.currentPage);
            this.$message.success(this.$t("common.tip.deleteSuccess"));
          }).catch(() => {
            this.$message.error(this.$t("common.tip.deleteError"));
          });
        });
      },
      changePage(pageIndex) {
        this.getList(pageIndex);
      },
      compareTodayDate(oldDateStr) {
        return new Date(oldDateStr).getTime() <= Date.now() - 3600 * 1000 * 24;
      },
      onSelectElevator(row) {
        this.adSearch.elevatorId = row.id;
        this.adSearch.elevatorName = row.name;
      },
      handleBatchDelete() {
        this.$refs.deletePage.open();
      },
      exportData() {
        let params = "";
        if (this.adSearch.elevatorId) {
          params = params + "elevatorId=" + this.adSearch.elevatorId + "&";
        }
        if (this.adSearch.dateRange && this.adSearch.dateRange.length > 1) {
          params = params + "startTime=" + this.adSearch.dateRange[0] + "&";
          params = params + "endTime=" + this.adSearch.dateRange[1] + "&";
        }
        window.open(window.config.integrationUrl + "cq/device/exportWb?" + params);
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
