<template>
  <el-container>
    <el-main style="padding:0;width:100vh">
      <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="elevators/passport">
        <template slot="adSearch">
          <vm-search :label="$t('maintWorkOrder.filter')">
            <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
          </vm-search>
          <vm-search :label="$t('elevator.elevatorType')">
            <el-select v-model="search.categoryCode" :style="{ width: $l('250px', '150px') }"
                       :placeholder="$t('elevator.elevatorType')" clearable>
              <el-option v-for="item in categoryList" :key="item.value" :label="item.label"
                         :value="item.value"></el-option>
            </el-select>
          </vm-search>
          <vm-search :label="$l('elevator.onlineStatus', '在线状态')">
            <el-select v-model="search.onlineStatus" :placeholder="$t('elevator.onlineStatus')"
              :style="{ width: $l('150px', '100px') }" clearable>
              <el-option v-for="item in onlineStatusOptions" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </vm-search>
          <vm-search :label="$l('elevator.joinStatus', '入网状态')">
            <el-select v-model="search.inNetState" :placeholder="$t('elevator.joinStatus')"
              :style="{ width: $l('150px', '100px') }" clearable>
              <el-option v-for="item in inNetStateOptions" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </vm-search>
        </template>
        <table-column prop="no" :label="$t('elevator.no')" :width="120" align="center">
          <template #default="scope">
            <router-link :to="'/elevator/' + scope.row.id" target="_blank">{{ scope.row.no }}</router-link>
          </template>
        </table-column>
        <table-column prop="regNo" :label="$t('elevator.registerCode')" :width="200"></table-column>
        <table-column prop="dtuCode" :label="$t('elevator.dtuCode')" :width="150" align="center"></table-column>
        <table-column prop="nodeCode" :label="$t('elevator.nodeCode')" :width="100" align="center"></table-column>
        <table-column prop="name" :label="$t('elevator.name')"></table-column>
        <table-column prop="useUnitName" :label="$t('elevator.useUnit')"></table-column>
        <table-column :label="$t('device.status')" align="center" :width="80">
          <template #default="scope">
            <el-tag type="success" v-if="scope.row.onlineStatus">{{$t("device.online")}}</el-tag>
            <el-tag v-else type="info">{{$t("device.offline")}}</el-tag>
          </template>
        </table-column>
        <table-column :label="$t('common.operation')" :width="100" align="center" fixed="right">
          <template #default="scope">
            <el-button type="text" @click="statusData(scope.row)">发送状态</el-button>
          </template>
        </table-column>
      </vm-table>
    </el-main>
  </el-container>
</template>
<script>
import VmTree from "../../../components/VmTree.vue";
import ElMain from "element-ui/packages/main/src/main.vue";

const moduleName = "elevators";
const moduleName_productType = "elevatorProductTypes";

export default {
  components: { ElMain },
  data() {
    return {
      loading: false,
      showAside: false,
      search: {
        filter: "",
        categoryCode: "",
        integrationKey: "jiangbei",
        maintComId: "",
        quantityOrganId: "",
        createComId: "",
        installComId: "",
        remakeComId: "",
        useUnitId: "",
        onlineStatus: "",
        inNetState: "",
        hasActivatedParts: "",
        leaveStartTime: "",
        leaveEndTime: "",
        installStartTime: "",
        installEndTime: "",
        inNetStartTime: "",
        inNetEndTime: "",
        useStartTime: "",
        useEndTime: "",
        districtId: "",
      },
      productTypeOptions: [],
      categoryList: [],
      activatedPartsStatus: [
        { value: "false", label: this.$t("elevator.notActivated") },
        { value: "true", label: this.$t("elevator.activated") },
      ],
      onlineStatusOptions: [
        { value: 0, label: this.$t("device.offline") },
        { value: 1, label: this.$t("device.online") },
      ],
      inNetStateOptions: [
        { value: "false", label: this.$t("elevator.notJoined") },
        { value: "true", label: this.$t("elevator.joined") },
      ],
    };
  },
  mounted() {
    this.getList(1);
    this.getProductTypeOptions();
  },
  methods: {
    getList(pageIndex) {
      this.loading = true;
      this.$refs.vmTable.getList(pageIndex);
      this.loading = false;
    },
    statusData(row) {
      let regCode = row.regNo;
      this.$http.get(window.config.integrationUrl + "jiangbei/sendStatus/" + regCode).then(res => {
        this.$message.success(this.$t("elevator.tip.operateSuccess"));
      }).catch((error) => {
        this.$message.error(this.$t("elevator.tip.operateError") + ", " + error.response.data.message);
      });
    },
    getProductTypeOptions() {
      this.$api.getList(moduleName_productType).then(response => {
        for (let productType of response.data) {
          let item2 = "";
          if (this.$i18n.isCn) {
            item2 = {value: productType.elevatorProductTypeNO, label: productType.elevatorProductTypeName};
          } else {
            item2 = {value: productType.elevatorProductTypeNO, label: productType.elevatorProductTypeNameEn};
          }
          this.categoryList.push(item2);
        }
      }).catch(() => {
        this.$message.error(this.$t("common.tip.getDataError"));
      });
    },
    handleCollapse() {
      this.showAside = !this.showAside;
      if (this.showAside) {
        this.$refs.aside.$el.setAttribute("style", "margin-right: 20px");
      } else {
        this.$refs.aside.$el.setAttribute("style", "margin-right: 0px");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.vm-main .vm-main-page .vm-content .vm-page {
  padding: 17px 22px 17px 0;
}
</style>
